<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="70"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="用户名:" prop="LoginName">
              <Input
                placeholder="请输入用户名"
                v-model="params.LoginName"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="IP地址:" prop="IP">
              <Input placeholder="请输入IP" v-model="params.IP" clearable />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="日期范围:">
              <Date-picker
                v-model="params.TempDate"
                ref="date"
                type="datetimerange"
                placement="bottom-end"
                placeholder="选择日期范围"
                style="width: 100%"
                format="yyyy-MM-dd HH:mm:ss"
                @on-change="dateUpdate"
                clearable
              ></Date-picker>
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="24" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
            >收起 <Icon type="ios-arrow-up"
          /></span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
            >搜索</Button
          >
          <Button class="ml2 search-form-container__button" @click="handleReset"
            >重置</Button
          >
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      stripe
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      size="small"
      @on-sort-change="sortChange"
    >
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
  </Layout>
</template>

<script>
import api from "@/api";
import layout from "@/components/layout/index.vue";
export default {
  name: "LoginLog",
  data() {
    return {
      tableLoading: false,
      tableHeight: 0,
      TempDate: "",
      // 日期快捷选择
      options: {
        shortcuts: [
          {
            text: "最近一周",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "最近一个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "最近三个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            },
          },
        ],
      },
      params: {
        LoginName: null,
        IP: null,
        DateBegin: null,
        DateEnd: null,
        Page: 1,
        PageSize: 10,
        SortName: "LoginDate",
        SortOrder: "Desc",
      },
      columns: [
        {
          title: "编号",
          key: "ID",
          minWidth: 100,

          align: "center",
          sortable: "custom",
        },
        {
          title: "用户名",
          key: "LoginName",
          minWidth: 111,

          align: "center",
          sortable: "custom",
        },
        {
          title: "IP地址",
          key: "LoginIP",
          minWidth: 130,

          align: "center",
          sortable: "custom",
        },
        {
          title: "登录时间",
          key: "LoginDate",
          minWidth: 150,
          sortable: "custom",

          align: "center",
        },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      showUserProfileDrawer: false,
    };
  },
  computed: {},
  created() {
    this.loadLogList();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadLogList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.loadLogList();
      }
    });
  },
  activated() {
    const self = this;
    document.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadLogList();
      }
    });
  },
  deactivated() {
    document.removeEventListener("keydown", function () {});
  },
  methods: {
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadLogList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadLogList();
    },
    // 远程排序
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadLogList();
    },
    // 日期变化方法
    dateUpdate(value) {
      this.params.DateBegin = value[0];
      this.params.DateEnd = value[1];
    },
    async loadLogList() {
      this.tableLoading = true;
      try {
        const res = await api.GetLoginLog(this.params);
        if (res.Status === 100) {
          this.resObj = res.Data;
          this.tableLoading = false;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadLogList();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.TempDate = "";
      this.params.DateBegin = null;
      this.params.DateEnd = null;
      this.loadLogList();
    },
  },
  components: { layout },
};
</script>
